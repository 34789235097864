<template>
  <div class="xtx-goods-page">
    <div class="container">
      <i class="el-icon-d-arrow-left backDiv" @click="goBack">Back</i>

      <!-- 商品信息 -->
      <div class="info-container">
        <div class="goods-info">

          <el-row :gutter="10">
            <!--产品图片-->
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div>
                <el-row :gutter="24" style="display: flex; ">
                  <el-col :span="4">
                    <div>
                      <div style="text-align: center; margin-bottom: 10px;">
                        <el-button size="mini" @click="prevPage" :disabled="currentPage === 1">上一页</el-button>
                      </div>
                      <el-row style="display: flex; flex-direction: column; align-items: center;">
                        <el-col :span="24"
                                v-for="image in paginatedImages"
                                :key="image"
                                style="margin-bottom: 10px;">
                          <img :src="image"
                               class="leftImgBox"
                               @click="selectImage(image)"
                               :style="{ border: image === selectedImage ? '2px solid green' : 'solid 2px rgb(217, 220, 222)' }">
                        </el-col>
                      </el-row>
                      <div style="text-align: center; margin-top: 10px;">
                        <el-button size="mini" @click="nextPage" :disabled="currentPage === totalPages">下一页</el-button>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <div style="width: 100%;">
                      <el-image style="border: 1px solid rgb(224, 224, 224);"
                                :src="selectedImage"
                                fit="cover"
                                :preview-src-list="images"
                      ></el-image>
                    </div>
<!--                    <img :src="selectedImage" style="width: 100%;border: 1px solid rgb(224, 224, 224);">-->
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <!--产品信息-->
            <el-col
                :xs="12"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
                style="border: 1px solid #e0e0e0; padding: 20px;"
                class="prodect-content"
            >
              <el-button
                  @click="collect()"
                  :type="productInfo.user_collect == 1 ? 'danger' : ''"
                  icon="el-icon-star-off"
                  size="mini"
                  circle
                  style="float: right"
              ></el-button>
              <div class="detailsInfo">
                <div class="topbox" style="margin-bottom: 20px">
                  <span class="name">{{ productInfo.name }}</span>
                  <span class="num" style="margin-left: 20px">{{
                      productInfo.code
                    }}</span>
                </div>
                <div class="description">
                  {{ productInfo.describe }}
                </div>
                <div class="price">
                  <div class="title">ENTER YOUR QUANTITY</div>
                  <el-table
                      style="width: 100%"
                      :data="getValues"
                      :cell-style="cellStyle"
                      border
                  >
                    <el-table-column
                        v-for="(item, index) in getHeaders"
                        :key="index"
                        :prop="item"
                        :show-header="false"
                    >
                    </el-table-column>
                  </el-table>
                  <div style="margin-top: 15px;height: 40px">
                    <el-input placeholder="" v-model="num" style="width: 275px;float: right" @input.native="handleChange" @change="changeNum">
                      <template slot="append">× {{productInfo.symbol}}{{ price }} EA</template>

                    </el-input>
                  </div>
                  <div style="height: 40px;float: right" v-if="priceTip">
                    <h4 style="color: red;">{{priceText}}</h4>
                  </div>
                </div>
                <div
                    class="material"
                    v-show="productInfo.sample_days"
                >
                  <div class="title">Sample days</div>
                  <div class="content">
                    {{ productInfo.sample_days }}
                  </div>
                </div>
                <div
                    class="material"
                    v-show="productInfo.material_str !== ''"
                >
                  <div class="title">METERIAL</div>
                  <div class="content">
                    {{ productInfo.material_str }}
                  </div>
                </div>
                <div
                    class="printing-mode"
                    v-show="productInfo.imprint_str !== ''"
                >
                  <div class="title">PRINTING MODE</div>
                  <div class="content">{{ productInfo.imprint_str }}</div>
                </div>
                <div
                    class="printing-size"
                    v-show="productInfo.imprint_area !== '' && productInfo.imprint_area !== null"
                >
                  <div class="title">PRINTING SIZE</div>
                  <div class="content">{{ productInfo.imprint_area }}</div>
                </div>
                <div class="color" v-show="productInfo.color !== ''">
                  <div class="title">COLOR</div>
                  <div class="content">{{ productInfo.color }}</div>
                </div>
                <div>
                  <el-table
                      :data="getAttrsValues"
                      style="width: 100%">
                    <el-table-column
                        v-for="(item, index) in getAttrsHeaders"
                        :key="index"
                        :prop="item.prop"
                        :label="item.label"
                        width="180">
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
    <img :src="productInfo.images[0]" alt="" style="width: 100%;height: 100%;">
    </el-dialog>
  </div>
</template>

<script>
// import PicZoom from "../components/PicZoom.vue";
import { productDetails, collectProduct } from "@/api/product";
import {iterator} from "core-js/stable/dom-collections";
export default {
  // components: { PicZoom },
  data() {
    return {
      dialogVisible: false,
      productInfo: {},
      // url: require("./110517_1.webp"),
      headers: [
        {
          prop: "quantity",
          label: "QTY",
        }, {
          prop: "delivered_price",
          label: "PRICE",
        },{
          prop: "transit_days",
          label: "Shipping Time",
        },
      ],
      productAttrs:[],
      tableData: [],
      num: "",
      selectedIndex: "",
      index: "",
      tableData1: [],
      price: "",
      priceTip:false,// 价格输入框底部的提示状态
      priceText:"",  // 价格输入框底部的提示文案
      currentPage: 1,
      pageSize: 6,
      images:[],
      selectedImage: null,
    };
  },
  mounted() {
    this.selectedIndex = 0;
    this.productDetails();
  },
  computed: {
    getHeaders() {
      return this.tableData1.reduce(
        (pre, cur, index) => pre.concat(`value${index}`),
        ["title"]
      );
    },
    getValues() {
      return this.headers.map((item) => {
        return this.tableData1.reduce(
          (pre, cur, index) =>
            Object.assign(pre, { ["value" + index]: cur[item.prop] }),
          { title: item.label }
        );
      });
    },
    getAttrsHeaders() {
      if (this.productAttrs.length > 0) {
        // 将 'keys' 字段解析为数组并生成属性表头
        const keys = this.productAttrs[0].keys.split(',');
        return keys.map((key) => ({
          prop: key.toLowerCase(),
          label: key,
        }));
      } else {
        return [];
      }
    },
    getAttrsValues() {
      if (this.productAttrs.length > 0) {
        // 将 'value' 字段解析为对象数组
        return this.productAttrs.map((item) => {
          const keys = item.keys.split(',');

          let attrValue = item.value;
          attrValue = attrValue.replace(/\\"/g, '"');//处理转义字符的显示

          const values = attrValue.split(',');
          const obj = {};
          keys.forEach((key, index) => {
            obj[key.toLowerCase()] = values[index];
          });
          return obj;
        });
      } else {
        return [];
      }
    },
    totalPages() {
      return Math.ceil(this.images.length / this.pageSize);
    },
    paginatedImages() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.images.slice(start, end);
    },
  },
  methods: {
    productDetails() {
      this.tableData1 = [];
      productDetails({
        id: this.$route.query.id,
        token: localStorage.getItem("token"),
        price_type: localStorage.getItem("price_type"),
      }).then((res) => {
        this.productInfo = res.data;
        this.images = res.data.images;
        this.selectFirstImage();
        this.tableData = res.data.price_data;
        this.tableData.forEach((item, index) => {
          this.tableData1.push({
            quantity: item.quantity + "+",
            delivered_price: res.data.symbol +' '+ item.delivered_price,
            transit_days: item.transit_days +' days',
          });
        });

        this.productAttrs = res.data.attrs;

        // 默认选中价格表第一列
        this.index = 1;
        this.num = this.tableData[0].quantity;
        this.price = this.tableData[0].delivered_price;
      });
    },
    handleClose(done) {
      done();
    },
    collect() {
      let user_collect = this.productInfo.user_collect;// 获取当前的收藏状态  便于操作后取反

      if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
        collectProduct({
          id: this.productInfo.id,
          token: localStorage.getItem("token"),
        }).then((res) => {
          this.productInfo.user_collect = user_collect == 1 ? 0 : 1;// 进行收藏操作后刷新收藏按钮的状态  无需刷新所有信息

          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      } else {
        this.$confirm("Login Required", "Tips", {
          confirmButtonText: "OK",
          cancelButtonText: "Canecl",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "login",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancel Login",
            });
          });
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // //console.log(row, column, rowIndex, columnIndex);
      if (columnIndex == this.index) {
        // 返回样式对象
        return { backgroundColor: "#c5e8c8" };
      }
      // 或者返回空对象，表示不应用任何样式
      return {};
    },
    handleChange() {
      // 只有当输入的值大于了最小起订量，才会取对应的价格表信息
      if (this.num >= this.tableData[0].quantity) {
        this.tableData.forEach((item, index) => {
          if (
            this.tableData[index].quantity <= this.num) {
            this.index = index + 1; // 价格表选中的列
            this.price = this.tableData[index].delivered_price;
          }
        });
      } else {
        this.index = 0; // 价格表选中的列
        this.price = "";
      }
    },
    changeNum() {
      this.priceTip = false;
      this.priceText = "";
      //当输入非数字时，更新为最低起订量
      if (isNaN(this.num) || this.num < 0 || this.num.includes('.')) {
        this.index = 1; // 价格表选中的列
        this.price = this.tableData[0].delivered_price;
        this.num = this.tableData[0].quantity;

        this.priceTip = true;
        this.priceText = "Please enter a positive integer.";
      }
      //当输入的值小于最低起订量时，更新为最低起订量
      if (this.num < this.tableData[0].quantity) {
        this.index = 1; // 价格表选中的列
        this.price = this.tableData[0].delivered_price;
        this.num = this.tableData[0].quantity;

        this.priceTip = true;
        this.priceText = "The minimum quantity for this product is "+this.tableData[0].quantity+".";
      }
    },
    goBack() {
      window.history.go(-1);
    },
    selectIten(item, index) {
      this.productInfo.images[0] = item;
      this.selectedIndex = index;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.selectFirstImage();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.selectFirstImage();
      }
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    selectFirstImage() {
      if (this.paginatedImages.length > 0) {
        this.selectedImage = this.paginatedImages[0];
      }
    }
  },
};
</script>
<style scoped lang='scss'>
.leftImgBox {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.backDiv {
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
}
.xtx-goods-page {
  .container {
    max-width: 90%;
    margin: 0 auto;
    //width: 80%;
  }
  .goods-info {
    min-height: 600px;
    background: #fff;
    margin: 0 30px 20px 0 ;
    .detailsInfo {
      .name {
        font-size: 28px;
        font-style: normal;
        font-weight: bold;
      }
      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
      }
      .price {
        margin-top: 20px;
        margin-bottom: 30px;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 16px;
        }
        .el-table .old-row {
          background-color: #f0f9eb; /* 需要更改的颜色 */
        }
        ::v-deep .el-table__header-wrapper {
          display: none;
        }
        .unitprice {
          float: left;
          height: 40px;
          background-color: #dde2e4;
          line-height: 30px;
          width: 90px;
          padding: 5px 10px;
          font-size: 14px;
        }
      }
      .material {
        clear: both;
        margin-top: 20px;
        .title {
          font-weight: bold;
          font-size: 16px;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .printing-mode {
        margin-top: 20px;
        .title {
          font-weight: bold;
          font-size: 16px;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .printing-size {
        margin-top: 20px;
        .title {
          font-weight: bold;
          font-size: 16px;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .color {
        margin-top: 20px;
        .title {
          font-weight: bold;
          font-size: 16px;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
    .media {
      height: 527px;
    }

    .spec {
      border: 1px solid #666;
      height: 500px;
    }
  }

  .goods-tabs {
    min-height: 600px;
    background: #fff;
  }

  .goods-warn {
    min-height: 600px;
    background: #fff;
    margin-top: 20px;
  }

  .number-box {
    display: flex;
    align-items: center;

    .label {
      width: 60px;
      color: #999;
      padding-left: 10px;
    }
  }

  .g-name {
    font-size: 22px;
  }

  .g-desc {
    color: #999;
    margin-top: 10px;
  }

  .g-price {
    margin-top: 10px;

    span {
      &::before {
        content: "¥";
        font-size: 14px;
      }

      &:first-child {
        //color: $priceColor;
        margin-right: 10px;
        font-size: 22px;
      }

      &:last-child {
        color: #999;
        text-decoration: line-through;
        font-size: 16px;
      }
    }
  }

  .g-service {
    background: #f5f5f5;
    width: 500px;
    padding: 20px 10px 0 10px;
    margin-top: 10px;

    dl {
      padding-bottom: 20px;
      display: flex;
      align-items: center;

      dt {
        width: 50px;
        color: #999;
      }

      dd {
        color: #666;

        &:last-child {
          span {
            margin-right: 10px;

            &::before {
              content: "•";
              //color: $xtxColor;
              margin-right: 2px;
            }
          }

          a {
            //color: $xtxColor;
          }
        }
      }
    }
  }

  .goods-sales {
    display: flex;
    width: 400px;
    align-items: center;
    text-align: center;
    height: 140px;

    li {
      flex: 1;
      position: relative;

      ~ li::after {
        position: absolute;
        top: 10px;
        left: 0;
        height: 60px;
        border-left: 1px solid #e4e4e4;
        content: "";
      }

      p {
        &:first-child {
          color: #999;
        }

        &:nth-child(2) {
          //color: $priceColor;
          margin-top: 10px;
        }

        &:last-child {
          color: #666;
          margin-top: 10px;

          i {
            //color: $xtxColor;
            font-size: 14px;
            margin-right: 2px;
          }

          &:hover {
            //color: $xtxColor;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.goods-tabs {
  min-height: 600px;
  background: #fff;

  nav {
    height: 70px;
    line-height: 70px;
    display: flex;
    border-bottom: 1px solid #f5f5f5;

    a {
      padding: 0 40px;
      font-size: 18px;
      position: relative;

      > span {
        //color: $priceColor;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.goods-detail {
  padding: 40px;

  .attrs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 10px;
      width: 50%;

      .dt {
        width: 100px;
        color: #999;
      }

      .dd {
        flex: 1;
        color: #666;
      }
    }
  }

  > img {
    width: 100%;
  }
}

.btn {
  margin-top: 20px;
}

.bread-container {
  padding: 25px 0;
}
</style>
<style>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.highLighted {
  border: 2px solid #4ab552 !important;
}
</style>
<style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-12 {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-12 {
    /* width: 33.3%; */
  }
}
@media screen and (max-width: 800px) {
  .el-col-lg-12 {
    width: 100%;
  }
}
</style>
